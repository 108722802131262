ul.mobilebar {
    padding: 0;
    list-style: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: white;
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: 100%;
    max-width: 480px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.tabledetail {
    margin-bottom: 40px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding-bottom: 30px;
}



ul.mobilebar:after {
    display: none;
}

ul.mobilebar {
    text-align: center;
}

ul.mobilebar {
    margin: 0;
    padding: 17px 28px 7px 28px;
    box-shadow: 0px -4px 30px -20px #0000008f;
    border-radius: 15px 15px 0px 0px;
    -webkit-border-radius: 15px 15px 0px 0px;
    -moz-border-radius: 15px 15px 0px 0px;
    -ms-border-radius: 15px 15px 0px 0px;
    -o-border-radius: 15px 15px 0px 0px;
}

ul.mobilebar a:after {
    display: none;
}

ul.mobilebar a {
    text-decoration: none;
    color: #000;
    display: block;
}

ul.mobilebar p {
    color: var(--Neutral-800, #1F2937);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin-top: 3px;
}

ul.mobilebar svg {
    width: 30px;
    height: 30px;
}

ul.mobilebar {
    z-index: 88888;
}

ul.mobilebar li a p {
    transition: all 0.5s;
    position: relative;
}

ul.mobilebar li a p:after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 38px;
    background: #5a0226;
    height: 5px;
    transition: all 0.5s;
    border-radius: 10px 10px 0px 0px;
    opacity: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}

ul.mobilebar span {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    color: #000;
}
ul.mobilebar li.active span{
color: #5a0226;
}
ul.mobilebar li.active a p:after {
    opacity: 1;
}
ul.mobilebar li{
    opacity: 0.5;
}

ul.mobilebar li a p{
    color: #000;
}
ul.mobilebar li.active{
    opacity: 1;
}

ul.mobilebar li.active {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
}

.itemtitle h3 {
    font-size: 16px !important;
    line-height: 22px !important;
}
ul.mobilebar a i {
    position: absolute;
    top: -8px;
    right: -10px;
    background: red;
    border-radius: 100%;
    color: #fff;
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

ul.mobilebar a {
    position: relative;
}

ul.mobilebar a p {margin-bottom: 6px;}