.container {
    max-width: 480px !important;
    width: 100%;
    margin: auto;
    padding: 0 !important;
}

/* Container for the entire progress bar component */
.progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
    margin-bottom: 40px;
}

/* Styling for the current step number */
.progress-number {
    font-size: 24px;
    margin-bottom: 20px;
}

/* Container for the progress bar itself */
.progress-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

}

/* Styling for the connecting line */
.progress-line {

    background-color: #e0e0e0;
    position: relative;
    margin: 0 10px;
}

/* Base styling for each circle on the progress bar */
.progress-circle {

    border-radius: 50%;
    background-color: #90c2af;
    position: absolute;

    cursor: pointer;
}



/* Styling for the active state of a circle */
.progress-circle.active {
    background-color: #007bff;
}

/* Container for the person selection list */
.person-selection {
    text-align: center;
}

/* List styling for the selectable people */
.person-selection ul {
    list-style: none;
    padding: 0;
}

.person-selection li {
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ddd;
    margin: 5px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

/* Highlight the selected person */
.person-selection li.selected {
    background-color: #007bff;
    color: white;
}

.progress-bar {
    /* border-radius: 100px; */
    /* background: var(--Primary-50, #E6F8F2) !important; */
    /* height: 12px; */
    width: 100%;
}

.progress-line {
    border-radius: 100px;
    background: var(--Primary-50, #E6F8F2);
    height: 12px;
    width: 100%;
    display: flex;

    position: relative;
    align-items: center;
    justify-content: space-between;
}

.progress-circle {
    width: 8px;
    height: 8px;
    position: relative;
    width: 20%;
    max-width: 8px;
}

.progress-circle.active {
    width: 42px;
    height: 42px;
    background: #e6f8f2;
    position: relative;
}

.progress-circle:after {
    transition: all 0.5s;
    content:
        "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 35px;
    background: #5a0226;
    border-radius: 100%;
    opacity: 0;
}

.progress-circle.active:after {
    opacity: 1;
}


.progress-circle.active:before {
    transition: all 0.5s;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 42px;
    height: 42px;
    background: #bcf1e0;
    border-radius: 100%;
    transition: all 0.5s;
}

.progress-bar {
    overflow: visible !important;
}

.progress-number {
    color: #000;
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.8px;
    line-height: 48px;
    margin-bottom: 32px;
}

body .modal-content {
    border-radius: 20px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.modal-body {
    padding: 0px !important;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.fade.automodal.modal.show {
    z-index: 999999;
}

.fade.singleitem.modal.show {
    z-index: 99999;
}

.fade.singleitem.modal.show+.fade {
    z-index: 999999;
}


.itemdetails.mb-5 .row {
    flex-direction: row;
    flex-wrap: nowrap;
}

.itemdetails.mb-5 .col-lg-8 {
    width: 60%;
}

.itemdetails.mb-5 .col-lg-4 {
    width: 40%;
}









.progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.progress-number {
    font-size: 24px;
    margin-bottom: 10px;
}

.progress-bar {
    width: 80%;
    position: relative;
    margin-bottom: 20px;
}

.progress-line {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    pointer-events: none;
    /* Prevents interfering with slider */
}

.progress-circle {
    width: 10px;
    height: 10px;
    background-color: lightgray;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    pointer-events: none;
    /* Prevents interfering with slider */
}

.progress-circle.active {
    background-color: green;
}

.progress-slider {
    width: 90%;
    -webkit-appearance: none;
    /* Remove default styles */
    appearance: none;
    background: #5a022614;
    /* Make the track transparent */
    cursor: pointer;
    height: 10px;
    border-radius: 4rem;
    margin-top: 20px;
}

.progress-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 35px;
    height: 35px;
    background: #5a0226;
    border-radius: 50%;
    margin-top: -5px;
    /* Center the thumb vertically with the circles */
}


.progress-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: green;
    border-radius: 50%;
}

.quickbitesslider.mb-5.mt-5 {
    margin-top: 0px !important;
    margin-bottom: 32px !important;
}

.searchmain {
    margin-bottom: 10px !important;
}


.offerbanner.mb-5 {}

.offerbanner.mb-5 {
    margin-bottom: 10px !IMPORTANT;
}

.quickbitesslider.mb-5 {
    margin-bottom: 30px !IMPORTANT;
}

p.text {
    text-align: center;
    color: var(--Neutral-500, #6B7280);
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.maindishtitle.quicktitle.mb-3 {
    margin-bottom: 9px !important;
}

.quickbitesslider.mb-5 {
    text-align: center;
}

.maintitle.d-flex h1 {
    font-weight: 800;
}

.maindishtitle h3 {
    font-weight: 800;
}

.dishname h4 {
    font-size: 10px !important;
}

.tabledetail {
    padding: 10px !important;
}

.offerbanner.mb-5 {
    margin-bottom: 10px !IMPORTANT;
}

.combosubdetail h3 {
    font-size: 14px !important;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 20px !important;
}

span.bluetag {
    font-size: 11px !important;
    font-weight: 700 !important;
    padding: 6px !important;
}

.bg-white.bg-green {
    background: #5a022614 !important;
}

.tabledetail.p-0 {
    overflow: auto;
    height: auto !important;
}

.mb-5.accordion {
    margin-bottom: 100px !important;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #5A0226 !important;

}

.bg-white.bg-white .row {
    align-items: center;
}