.searchmain input {

    padding: 8px 12px;

    width: 100%;

    display: flex;

    height: 42px;

    padding: 8px 42px 8px 40px;

    align-items: center;

    gap: 12px;

    align-self: stretch;

    border-radius: 8px;

    background: var(--Generic-White, #FFF);

    /* Drop shadow/Large */

    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.05), 0px 8px 24px -3px rgba(16, 24, 40, 0.10);

    border: none;

    color: var(--Neutral-400, #9CA3AF);

    /* Label/Medium/Regular */

    font-family: "Inter", sans-serif;

    font-size: 14px;

    font-style: normal;

    font-weight: 400;

    line-height: 16px;
    /* 114.286% */

}

.searchmain i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 11px;
}

.searchmain {
    position: relative;
}

.searchmain span {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 8px;
    background: var(--Success-50, #F0FDF4);
    padding: 8px;
}

.tabledetail {
    border-radius: 0 !important;
    background: #ffffff;
    padding: 14px;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.dishname img {
    /* width: auto !IMPORTANT; */
    border-radius: 100%;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    text-align: center;
}

.dishname h4 {
    color: var(--Neutral-800, #1F2937);
    text-align: center;

    /* Label/Small/Medium */
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 116.667% */
}

.dishname span {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    margin: auto;
    margin-bottom: 8px;
    /* filter: drop-shadow(0px 6px 13px lightgray); */
}

.dishname {
    padding-top: 8px;
    text-decoration: none;
}

html {}

.fade.modal {
    max-width: 480px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.fade.modal-backdrop {
    max-width: 480px;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
}

.maintitle.d-flex h1+a {
    margin-left: auto;
}

body {
    background: #f9fafb !important
}

.modal-dialog {
    max-width: 480px !important;
    margin: 0 !important;
}


.singleitem .modal-body {
    height: calc(100vh - 180px);
    overflow: auto;
}

.combomodal .modal-body {
    height: calc(100vh - 180px);
    overflow: auto;
}

.modal-header {
    position: absolute;
    top: -66px;
    left: 50%;
    transform: translateX(-50%);
    border: none !important;
}

.modal-body {}

.modal-header button {
    background-color: #ababab;
    border-radius: 100%;
    color: white;
}

.maintitle.d-flex h1+a {
    margin-left: auto;
}

.ratingmain span {
    margin-left: auto;
}

.ratingmain p {
    border-radius: 3px;
    border: 0.45px solid var(--Secondary-Slate-500, #64748B);
    background: var(--Neutral-50, #F9FAFB);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Neutral-500, #6B7280) !important;
    font-family: Inter !important;
    font-size: 7px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 100% !important;
    /* 7px */
    padding: 3px !important;
    margin-left: 6px !important;
}