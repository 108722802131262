.loading-state {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 480px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}
  .loading {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid #ddd;
    border-top-color: orange;
    animation: loading 1s linear infinite;
  }
  @keyframes loading {
    to {
      transform: rotate(360deg);
    }
  }
  