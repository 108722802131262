.searchmain input {
    padding: 8px 12px;
    width: 100%;
    display: flex;
    height: 42px;
    padding: 8px 8px 8px 40px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Generic-White, #FFF);
    /* Drop shadow/Large */
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.05), 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    border: none;
    color: var(--Neutral-400, #9CA3AF);
    /* Label/Medium/Regular */
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
    border: 1px solid #EDEAEA,
}

.searchmain i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 11px;
}

.searchmain {
    position: relative;
    display: flex;
    align-items: center;
}

.searchmain span {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 8px;
    background: var(--Success-50, #F0FDF4);
    padding: 0px;
}

.tabledetail {
    border-radius: 16px;
    background: #FFFFFF;
    padding: 14px;
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-toggle-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    min-width: 200px;
    border-radius: 4px;
    overflow: hidden;
}

.dropdown-item {
    padding: 4px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-item:hover {
    background-color: #F1F1F1;
}

.dropdown-image {
    width: 40px;
    height: 24px;
    /* margin-right: 10px; */
}

.image-container {
    display: flex;
    gap: 20px;
}

.image-option {
    cursor: pointer;
}

.image-label {
    display: block;
    text-align: center;
    margin-top: 5px;
}

.selected-image-container {
    margin-top: 20px;
}

.selected-image {
    width: 25px;
    /* Adjust as needed */
    height: 25px;
    /* Adjust as needed */
    display: block;
    margin: 10px auto;
}

.selected-image-container {
    position: absolute;
    right: 6px;
    cursor: pointer;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
}

.image-container {
    position: absolute;
    flex-direction: column;
    right: 0px;
    z-index: 99999;
    background: white;
    padding: 0px 5px;
    border-radius: 5px;
    gap: 10px;
    height: 0;
    transition: all 0.5s;
    overflow: hidden;
}

img.selected-image {
    margin: 0;
}

.image-container.show {
    height: auto;
    padding: 5px;
}

.image-option img {
    width: 25px;
    height: 25px;
}

.selected-image-container {
    display: flex;
    align-items: center;
}

.selected-image-container p {
    margin: 0;
    color: var(--Neutral-600, #4B5563);
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 0px;
    margin-right: 6px;
}

button#dropdown-basic-button {
    display: flex;
    align-items: center;
    border: none;
    color: black;
    gap: 10px;
    background: none;
}

.dropdown-menu {
    min-width: 0 !important;
    padding: 0 !important;
    border: none !important;
    /* width: 100%; */
    /* max-width: 22px; */
}