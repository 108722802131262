.successimg {
    text-align: center;
    padding: 47px 27px;
    background: #567663;
    border-radius: 15px;
}

.successimg h4 {
    color: var(--Neutral-50, #F9FAFB);
    text-align: center;

    /* Heading/H4/Medium/Desktop */
    font-family: "Inter", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    /* 128.571% */
    letter-spacing: -0.56px;
    margin-bottom: 40px;
}

.successimg span {
    margin-bottom: 0px;
    display: block;
}

.successimg a.bg-white {
    color: var(--primary-500-main, #5a0226);
    text-align: center;

    /* Label/Large/Medium */
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 112.5% */
    text-decoration: none;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    margin-bottom: 23px;
}

.successlinks {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.successlinks a {
    color: var(--Primary-50, #E6F8F2);
    text-align: center;

    /* Label/Large/Medium */
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 112.5% */
    text-decoration: none;
}

ul.patern {
    margin: 0;
    display: flex;
    gap: 13px;
    top: -22px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
}

.successimg {
    position: relative;
}

ul.patern:after {
    display: none;
}

ul.patern i {
    height: 40px;
    display: flex;
    background: #f7f8f9;
    border-radius: 100%;
    width: 40px;
}