.offertab {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.offertab span {}

span.bluetag {
    padding: 4px;
    border-radius: 24px;
    background: var(--Secondary-Sky-50, #F0F9FF);
    color: var(--Secondary-Sky-600, #0284C7);
    text-align: justify;
    font-family: "Inter", sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 8px */
    margin-right: auto;
}

.combodetail {
    border-radius: 16px;
    background: var(--Generic-White, #FFF);
    /* min-height: 280px; */
    /* Drop shadow/Small */
    box-shadow: inset 0px 2px 10px 0px rgba(16, 24, 40, 0.06);
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.combosubdetail {
    padding: 4px;
}

.combosubdetail h3 {
    color: #000;

    /* Paragraph/Large/Medium */
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
}

.comboprice {
    display: flex;
    align-items: center;
}

.comboprice p {
    color: var(--Generic-Black, #000);
    text-align: justify;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 14px */
    margin-bottom: 0;
    margin-right: auto;
}

.comboprice p del {
    color: var(--Neutral-400, #9CA3AF);
    text-align: justify;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    /* 9.8px */
    text-decoration-line: strikethrough;
}

.comboprice a {
    border-radius: 58.802px;
    border: 0.817px solid var(--primary-500-main, #5a0226);
    background: var(--primary-500-main, #5a0226);
    box-shadow: 0px 0.817px 1.633px 0px rgba(16, 24, 40, 0.04), 0px 0.817px 1.633px 0px rgba(16, 24, 40, 0.04);
    padding: 6px 10px;
    display: inline-flex;
    align-items: center;
    color: var(--Generic-White, #FFF);
    text-align: justify;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 10px */
    text-decoration: none;
}

.comboprice {}



.modal.fade .modal-dialog {
    transition: transform .3s ease-out !important;
    transform: translate(0, 50px) !important;
    -webkit-transition: transform .3s ease-out !important;
    -moz-transition: transform .3s ease-out !important;
    -ms-transition: transform .3s ease-out !important;
    -o-transition: transform .3s ease-out !important;
    -webkit-transform: translate(0, 50px) !important;
    -moz-transform: translate(0, 50px) !important;
    -ms-transform: translate(0, 50px) !important;
    -o-transform: translate(0, 50px) !important;
}

.modal-dialog {
    position: fixed !important;
    bottom: 0 !important;
    left: 0% !important;
    right: 0% !important;
    margin-bottom: 0 !important;

}

.modal.show .modal-dialog {
    transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
}

.modal-content {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

}

.saladimgs {
    position: relative;
}

a.plusicon {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
}

.maindishtitle {
    display: flex;
    justify-content: center;
}



.combodis {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 9px;
}

.combodis p {
    width: 50%;
    margin: 0;
}

.combos-container {
    max-height: 0;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;
    transition: max-height 0.6s ease, transform 0.6s ease;
    padding: 0;
}

/* Show state for CombosSlider container */
.combos-container.show {
    max-height: 1000px;
    /* Adjust to ensure it fits the content */
    transform: scaleY(1);
    padding: 20px 0;
}

a.vegicon {
    position: absolute;
    left: 4px;
    top: 4px;
    background: white;
    width: 12px;
    height: 12px;
    border-radius: 3px;
}

.bg-white.bg-green {
    /* background: transparent !important; */
    border: 1px solid #5a0226;
}

.bg-white {
    border: 1px solid var(--Generic-White, #FFF);
    transition: all 0.5s;
}

a.nonvegicon {
    position: absolute;
    right: 4px;
    top: 4px;
    background: white;
    width: 12px;
    height: 12px;
    border-radius: 3px;
}