.maintitle.d-flex h1 {
    color: var(--Neutral-800, #1F2937);

    /* Label/Large/Semi Bold */
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 112.5% */
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    cursor: pointer;
}

.maintitle.d-flex {
    /* padding: 16px; */
    margin-bottom: 16px;
}

.maintitle.d-flex a {
    margin-left: auto;
}

.table-number:hover {
    cursor: pointer;
}