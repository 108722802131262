p.para {
    color: #000000;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
}

.ratingmain span img {
    width: 100% !important;
    height: 100% !important;
}